import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import UIConstants from '../../../theme/constants/ui-constants'
import { CheckInput } from '../../../theme/components/input/CheckBox'
import { useTranslation } from 'react-i18next'
import { CustomButton } from '../../../theme/components/buttons/Button'
import { InputText } from '../../../theme/components/input/TextField'
import { useEffect, useState } from 'react'
import DatePicker from '@mui/lab/DatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import background from '../../../assets/images/background.png'
import logo from '../../../assets/images/logo.svg'
import mobileLogo from '../../../assets/images/logo_mobile.svg'
import { useNavigate } from 'react-router-dom'
import {
  CreateActionModel,
  SaveBusinessInfoActionModel,
  SaveUserInfoActionModel,
  SaveBusinessActionModel,
  UserData,
} from '../../../store/app/user/types'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import _ from 'lodash'
import LocationSelector from '../../../theme/components/input/LocationSelector'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchBusinessTypes,
  getSubscriptionTypes,
  saveAffiliate,
} from '../../../store/app/user/actions'
import { ApplicationState } from '../../../store'
import { PhoneInput } from '../../../theme/components/input/PhoneField'
import SubscriptionModal from '../../../app.v2/component/SubscriptionModal'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: UIConstants.rootBgColor,
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'contain',
    backgroundPosition: 'center top',
    minHeight: `100vh`,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      backgroundSize: 'cover',
    },
  },
  logodiv: {
    minHeight: '9rem',
    position: 'fixed',
    top: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginTop: '15%',
      position: 'unset',
      top: 'unset',
      width: 'auto',
    },
  },
  logo: {
    height: '40px',
    [theme.breakpoints.up('sm')]: {
      height: '70px',
      marginLeft: '15%',
    },
  },
  signupBox: {
    background: UIConstants.rootBgColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    padding: `0 ${theme.spacing(3)}`,
    position: 'relative',
    top: '4rem',
    height: 'calc(100vh - 9rem)',
    [theme.breakpoints.up('sm')]: {
      position: 'unset',
      maxWidth: '400px',
      height: '100%',
      margin: 'auto',
      top: 'unset',
      padding: `${theme.spacing(3)}`,
    },
  },
  heading: {
    margin: `${theme.spacing(1)} ${theme.spacing(4)}`,
    '& .mainHeading': {
      fontWeight: 400,
      fontSize: '18px',
      fontFamily: UIConstants.appFont,
      color: UIConstants.primaryBgColor,
    },
    '& .subHeading': {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '27px',
      fontFamily: UIConstants.appFont,
      color: UIConstants.primaryBgColor,
      opacity: 0.5,
    },
  },
  signUpInputBox: {
    minWidth: '250px',
    margin: `${theme.spacing(4)} 0`,
    width: '100%',
  },
  formControl: {
    margin: `${theme.spacing(3)} ${theme.spacing(1)}`,
    display: 'flex',
    // flexDirection: 'column-reverse',
  },
  becomeAHost: {
    color: UIConstants.primaryBgColor,
    margin: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  termsAndCondition: {
    color: UIConstants.primaryBgColor,
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      opacity: '0.5',
    },
  },
  labelOtherSignup: {
    color: UIConstants.primaryBgColor,
    fontSize: '14px',
    opacity: '0.5',
  },
  inputBorderPrimary: {
    '&.MuiInputBase-root': {
      '& > fieldset': {
        borderColor: UIConstants.primaryBgColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: UIConstants.primaryBgColor,
      },
    },
  },
  suggestion: {
   marginTop: '-15px',
   fontSize: '12px',
   fontStyle: 'italic',
   color: UIConstants.suggestion,
  },
}))

interface PersonalInfoProps {
  userData: UserData
  saveUserInfo: (user: SaveUserInfoActionModel) => void
  createUser: (model: CreateActionModel) => void
  saveBusinessInfo: (data: SaveBusinessInfoActionModel) => void
  saveBusiness: (data: SaveBusinessActionModel) => void
}

interface IFormInput {
  firstName: string
  lastName: string
  dob: Date
  email: string
  mobile_number: string
  subscribeMessage: boolean
  home_address?: string
  business_industry?: string
  business_name?: string
  business_address?: any
  username: string
}

const isValidEmail = (email: string) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

export default function PersonalInfo({
  userData,
  createUser,
  saveUserInfo,
  saveBusinessInfo,
  saveBusiness,
}: PersonalInfoProps) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const [selectedLocationData, setSelectedLocationData] = useState<string>('')
  const [homeAddress, setHomeAddress] = useState<string>('')
  const [savingUser, setSavingUser] = useState<boolean>(false)
  const [savingUserInfo, setSavingUserInfo] = useState<boolean>(false)
  const [savingBusiness, setSavingBusiness] = useState<boolean>(false)
  const [creatingUserAccount, setCreatingUserAccount] = useState<any>(null)
  const [formData, setFormData] = useState<any>('')
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(true);
  const { loading, isApiError, sugesstionUsername, businessTypes, subscriptionTypes } = useSelector((state: ApplicationState) => state.user)
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const appLogo = matches ? logo : mobileLogo
  const countries = process.env.REACT_APP_SUPPORTED_COUNTRIES?.split(',')
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IFormInput>({
    mode: 'all',
  })
  
  const affiliateCode = localStorage.getItem('affiliate_code') ?? '';

  const navigate = useNavigate()

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    setFormData(data);
    setSubscriptionModalOpen(true);
    // userData.mobile_number = data.mobile_number
    // userData.email = data.email
    // userData.corporate_id = null
    // createUser({ userData, navigate })
    // setSavingUser(true)
    // setCreatingUserAccount(true)
    // navigate('/')
  }

  const handleSubscriptionSuccess = () => {
    if (!formData) return;

    // Proceed to save user credentials after subscription
    userData.mobile_number = formData.mobile_number;
    userData.email = formData.email;
    userData.corporate_id = null;
    createUser({ userData, navigate });
    setSavingUser(true);
    setCreatingUserAccount(true);
  };

  useEffect(() => {
    dispatch(getSubscriptionTypes());
  }, [dispatch])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!loading.userData && savingUser) {
      const user = _.cloneDeep(userData)
      user.email = formData.email
      user.user_info = {
        ...user.user_info,
        first_name: formData.firstName,
        last_name: formData.lastName,
        date_of_birth: formData.dob,
        receive_msg: formData.subscribeMessage,
        email: formData.email,
        home_address: homeAddress,
        username: formData.username,
        //ADD LOGIN_TYPE
      }

      // let's create an affiliate here now.
      if(affiliateCode !== '') {
        dispatch(saveAffiliate(affiliateCode, user.id,))
      }

      saveUserInfo({
        reqModel: {
          user_info: user.user_info,
          user_id: user.id,
          user_type: user.user_type,
          email: formData.email,
          mobile_number: formData.mobile_number,
          language: 'en',
          country_code: userData.country_code,
          username: formData.username,
          login_type: userData.user_info !== undefined ? 'email' : 'phone',
        },
        navigate,
      })
      setSavingUser(false)
      setSavingUserInfo(true)
    }
  }, [savingUser, loading.userData])

  useEffect(() => {
    if (!loading.userInfo && savingUserInfo) {
      const user = _.cloneDeep(userData)
      if(user.user_info) {
        console.log(`Check userData 1:  ${JSON.stringify(user, null, 2)}`)
        saveBusinessInfo({
          reqModel: {
            businessName: formData.business_name,
            businessIndustry: formData.business_industry,
            location: selectedLocationData,
            user_id: user.id,
          },
          navigate,
        })
        setSavingUserInfo(false)
        setSavingBusiness(true)
      }
    }
  }, [savingUserInfo, loading.userInfo, userData.user_info])

  useEffect(() => {
    if(!loading.userInfo && savingBusiness) {
      const user = _.cloneDeep(userData)
      console.log(`Check userData 2:  ${JSON.stringify(user, null, 2)}`)

      saveBusiness({
        reqModel: {
          business_name: formData.business_name,
          business_address: selectedLocationData,
          user_id: user.id
        },
        navigate,
      })
      setSavingBusiness(false)
    }
  }, [savingBusiness, loading.userInfo])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (userData) {
      dispatch(fetchBusinessTypes())
    }
  }, [userData, dispatch])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.root}>
          <div className={classes.logodiv}>
            {' '}
            <img src={appLogo} alt={t('appName')} className={classes.logo} />
          </div>
          <Paper elevation={matches ? 3 : 0} className={classes.signupBox}>
            <div className={classes.heading}>
              <Typography className="mainHeading">
                {t('personalInfo.mainHeading')}
              </Typography>
            </div>
            <div className={classes.signUpInputBox}>
              <div className={classes.formControl}>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue={userData.user_info?.first_name}
                  render={({ field }) => (
                    <InputText
                      fullWidth
                      variant="outlined"
                      error={!!errors?.firstName}
                      size="small"
                      label={t('personalInfo.firstNameLabel')}
                      {...register('firstName', {
                        required: t('signUp.required') as string,
                      })}
                      {...field}
                      helperText={errors?.firstName?.message}
                    />
                  )}
                />
              </div>
              <div className={`${classes.formControl} flex flex-col`}>
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue={userData.user_info?.last_name}
                  render={({ field }) => (
                    <InputText
                      fullWidth
                      variant="outlined"
                      error={!!errors?.lastName}
                      size="small"
                      label={t('personalInfo.lastNameLabel')}
                      {...register('lastName', {
                        required: t('signUp.required') as string,
                      })}
                      {...field}
                      helperText={errors?.lastName?.message}
                    />
                  )}
                />
                <span className="text-subText text-xs">
                  Make sure it matches with your government ID
                </span>
              </div>
              <div className={`${classes.formControl} flex flex-col`}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Controller
                    name="dob"
                    control={control}
                    defaultValue={new Date()}
                    render={({ field }) => (
                      <DatePicker
                        disableFuture
                        label={t('personalInfo.birthDateLabel')}
                        openTo="year"
                        views={['year', 'month', 'day']}
                        value={field.value}
                        onChange={field.onChange}
                        renderInput={(params: any) => (
                          <InputText
                            fullWidth
                            variant="outlined"
                            size="small"
                            {...params}
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
                <span className="text-subText text-xs">
                  You need to be at least 18 years old
                </span>
              </div>
              <div className={classes.formControl}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue={userData.user_info?.email}
                  render={({ field }) => (
                    <InputText
                      disabled={userData.user_info !== undefined}
                      fullWidth
                      variant="outlined"
                      error={!!errors?.email}
                      size="small"
                      label={t('personalInfo.eMailLabel')}
                      {...register('email', {
                        required: t('signUp.required') as string,
                        validate: {
                          isValidEmail: (value) =>
                            isValidEmail(value)
                              ? true
                              : (t('personalInfo.invalidEmailId') as string),
                        },
                      })}
                      {...field}
                      helperText={errors?.email?.message}
                    />
                  )}
                />
              </div>
              <div className={classes.formControl}>
                <Controller
                  name="username"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputText
                      fullWidth
                      variant="outlined"
                      error={!!errors?.username}
                      size="small"
                      label={t('personalInfo.username')}
                      {...register('username', {
                        required: t('signUp.required') as string,
                      })}
                      {...field}
                      helperText={errors?.username?.message}
                    />
                  )}
                />
              </div>
              { sugesstionUsername && sugesstionUsername.length > 0 && 
              <div className={classes.formControl}>
                <p className={classes.suggestion}><b>suggestion</b>: {sugesstionUsername.join(', ')}</p>
              </div>
              }
              <div className={classes.formControl}>
                <PhoneInput
                  onlyCountries={countries}
                  fullWidth
                  label="Phone Number"
                  variant="outlined"
                  disabled={userData?.mobile_number !== undefined}
                  defaultValue={userData?.mobile_number}
                  size="small"
                  defaultCountry={'us'}
                  control={control}
                  name={'mobile_number'}
                  error={errors?.mobile_number}
                  helperText={errors?.mobile_number?.message}
                ></PhoneInput>
              </div>
              <div className={classes.formControl}>
                <Controller
                  name="home_address"
                  control={control}
                  defaultValue={''}
                  rules={{
                    required: 'Location required' as string,
                  }}
                  render={({ field }) => (
                    <LocationSelector
                      {...field}
                      label="Home Address"
                      setSelectedLocationData={setHomeAddress}
                      size="small"
                      error={errors?.home_address}
                      helperText={errors?.home_address?.message}
                    />
                  )}
                />  
              </div>
              {userData.user_type === 1 && (
                <>
                  <hr />
                  <div className={classes.formControl}>
                    <Controller
                      name="business_industry"
                      control={control}
                      render={({ field }) => (
                        <FormControl size="small" fullWidth>
                          <InputLabel
                            sx={{
                              color: UIConstants.primaryBgColor,
                              '&.Mui-focused': {
                                color: UIConstants.primaryBgColor,
                              },
                            }}
                          >
                            Business Industry
                          </InputLabel>
                          <Select
                            {...field}
                            label="Business Type"
                            {...register('business_industry', {
                              required: 'This field is required',
                            })}
                            error={!!errors?.business_industry}
                            className={classes.inputBorderPrimary}
                          >
                            {businessTypes?.map((business) => (
                              <MenuItem value={business.name} key={business.id}>
                                {business.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </div>
                  <div className={classes.formControl}>
                    <Controller
                      name="business_name"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          fullWidth
                          variant="outlined"
                          error={!!errors?.business_name}
                          size="small"
                          label="Business Name"
                          {...register('business_name', {
                            required: 'Business name is required' as string,
                          })}
                          {...field}
                          helperText={errors?.business_name?.message}
                        />
                      )}
                    />
                  </div>
                  <div className={classes.formControl}>
                    <Controller
                      name="business_address"
                      control={control}
                      defaultValue={''}
                      rules={{
                        required: 'Location required' as string,
                      }}
                      render={({ field }) => (
                        <LocationSelector
                          {...field}
                          label="Business Address"
                          setSelectedLocationData={setSelectedLocationData}
                          size="small"
                          error={errors?.business_address}
                          helperText={errors?.business_address?.message}
                        />
                      )}
                    />
                  </div>
                </>
              )}
              <div className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Controller
                      name="subscribeMessage"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <CheckInput                                                                                                                                                
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  }
                  label={t('personalInfo.receiveMessage') as string}
                  className={classes.termsAndCondition}
                />
              </div>
              <div className={classes.formControl}>
                <CustomButton
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={!isValid}
                >
                  {creatingUserAccount && !isApiError ? (
                    <CircularProgress size={25} />
                  ) : (
                    t('personalInfo.continue')
                  )}
                </CustomButton>
              </div>
            </div>
          </Paper>
        </div>
      </form>
      {subscriptionTypes && isValid && (
        <SubscriptionModal
          open={subscriptionModalOpen}
          onClose={() => setSubscriptionModalOpen(false)}
          products={subscriptionTypes}
          registrationData={formData}
          onSubscriptionSuccess={handleSubscriptionSuccess}
        />
      )}
    </>
  )
}
