export enum UserActionTypes {
  SET_USER = '@@user/SET_USER',
  SET_VALID_USER = '@@user/SET_VALID_USER',
  CREATE_USER = '@@user/CREATE_USER',
  UPDATE_USER = '@@user/UPDATE_USER',
  GET_SPECIFIC_USER = '@@user/GET_SPECIFIC_USER',
  SET_SPECIFIC_USER = '@@user/SET_SPECIFIC_USER',
  UPDATE_SUBSCRIPTION_STATUS = '@@user/UPDATE_SUBSCRIPTION_STATUS',
  UPDATE_PACKAGE_SUBSCRIBED = '@@user/UPDATE_PACKAGE_SUBSCRIBED',
  SAVE_USER_INFO = '@@user/SAVE_USER_INFO',
  CREATE_STRIPE_CUSTOMER = '@@user/CREATE_STRIPE_CUSTOMER',
  FETCH_PRICE_MODEL = '@@user/FETCH_PRICE_MODEL',
  SET_SUBSCRIPTION_DETAILS = '@@user/SET_SUBSCRIPTION_DETAILS',
  SET_PRICE_MODEL = '@@user/SET_PRICE_MODEL',
  CHECKOUT_SUBSCRIPTION = '@@user/CHECKOUT_SUBSCRIPTION',
  CONNECT_STRIPE = '@@user/CONNECT_STRIPE',
  SUBMIT_STRIPE_REQUIREMENTS='@@user/SUBMIT_STRIPE_REQUIREMENTS',
  LOGIN = '@@user/LOGIN',
  LOGOUT = '@@user/LOGOUT',
  FETCH_USER = '@@user/FETCH_SER',
  UPGRADE_USER = '@@user/UPGRADE_USER',
  CREATE_USER_LOADING = '@@user/CREATE_USER_LOADING',
  FETCH_USER_FLAGGING = '@@user/FETCH_USER_FLAGGING',
  SET_USER_FLAGGING = '@@user/SET_USER_FLAGGING',
  FETCH_PRICE_ESTIMATE = '@@user/FETCH_PRICE_ESTIMATE',
  SET_PRICE_ESTIMATE = '@@user/SET_PRICE_ESTIMATE',
  SETTLEMENT = '@@user/SETTLEMENT',
  SEND_VIP_REQUEST = '@app/SEND_VIP_REQUEST',
  SEND_CONTACT_US_INFO = '@app/SEND_CONTACT_US_INFO',
  SEND_CONTACT_US_INFO_SUCESS = '@app/SEND_CONTACT_US_INFO_SUCESS',
  SAVE_BUSINESS_INFO = '@@user/SAVE_BUSINESS_INFO',
  SAVE_LOGIN_CHECK_DATA = '@@user/SAVE_LOGIN_CHECK_DATA',
  SWITCH_USER_FOR_LOGIN = '@@user/SWITCH_USER_FOR_LOGIN',
  CLEAR_LOGIN_CHECK_DATA = '@@user/CLEAR_LOGIN_CHECK_DATA',
  DECLINE_USER_FLAGGING = '@@user/DECLINE_USER_FLAGGING',
  ZIP_CODE_CHECK = '@@user/ZIP_CODE_CHECK',
  ZIP_CODE_ELIGIBILE_STATUS = '@@user/ZIP_CODE_ELIGIBILE_STATUS',
  SET_SAVING_USER_INFO = '@@user/SET_SAVING_USER_INFO',
  FETCH_BUSINESS_INFO = '@@user/FETCH_BUSINESS_INFO',
  ZIP_CODE_IS_SIGNUP_FORM = '@@user/ZIP_CODE_IS_SIGNUP_FORM',
  SET_BUSINESS_INFO = '@@user/SET_BUSINESS_INFO',
  SET_WAITLIST_EMAIL = '@@user/SET_WAITLIST_EMAIL',
  SET_USER_DISCOUNTS = '@@user/SET_USER_DISCOUNTS',
  FETCH_BUSINESS_TYPES = '@@user/FETCH_BUSINESS_TYPES',
  SET_BUSINESS_TYPES = '@@user/SET_BUSINESS_TYPES',
  UPDATE_PROFILE_PICTURE = '@@user/UPDATE_PROFILE_PICTURE',
  SET_PROFILE_PICTURE = '@@user/SET_PROFILE_PICTURE',
  USE_BATHROOM = '@@user/USE_BATHROOM',
  SET_USE_BATHROOM_LOADING = '@@user/SET_USE_BATHROOM_LOADING',
  GET_PRODUCT_LIST = '@@user/GET_PRODUCT_LIST',
  SET_PRODUCT_LIST = '@@user/SET_PRODUCT_LIST',
  USER_BUSINESS_STRIPE = '@@user/USER_BUSINESS_STRIPE',
  SET_USER_BUSINESS_STRIPE = '@@user/SET_USER_BUSINESS_STRIPE',
  SET_USER_RETRIEVE_SUBSCRIPTION = '@@user/SET_USER_RETRIEVE_SUBSCRIPTION',
  USER_RETRIEVE_SUBSCRIPTION = '@@user/USER_RETRIEVE_SUBSCRIPTION',
  USER_CANCEL_SUBSCRIPTION = '@@user/USER_CANCEL_SUBSCRIPTION',
  USER_UPGRADE_SUBSCRIPTION = '@@user/USER_UPGRADE_SUBSCRIPTION',
  USER_CREATE_SUBSCRIPTION = '@@user/USER_CREATE_SUBSCRIPTION',
  BATHROOM_CHALLENGE = '@@user/BATHROOM_CHALLENGE',
  SET_BATHROOM_CHALLENGE = '@@user/SET_BATHROOM_CHALLENGE',
  SAVE_BATHROOM_CREDENTIAL = '@@user/SAVE_BATHROOM_CREDENTIAL',
  SET_BATHROOM_FETCH = '@@user/SET_BATHROOM_FETCH',
  UPDATE_USER_BUSINESS_DISCOUNT = '@@user/UPDATE_USER_BUSINESS_DISCOUNT',
  SET_STRIPE_ONBOARDING_STATUS = '@@user/SET_STRIPE_ONBOARDING_STATUS',
  STRIPE_ONBOARDING_STATUS = '@@user/STRIPE_ONBOARDING_STATUS',
  GET_SPECIFIC_PRODUCT = '@@user/GET_SPECIFIC_PRODUCT',
  SET_SPECIFIC_PRODUCT = '@@user/SET_SPECIFIC_PRODUCT',
  SET_API_ERROR = '@@user/SET_API_ERROR',
  SET_SUGGESTION_USERNAME = '@@user/SET_SUGGESTION_USERNAME',
  SAVE_BUSINESS = '@@user/SAVE_BUSINESS',
  LOADING_START = '@@user/LOADING_START',
  LOADING_STOP = '@@user/LOADING_STOP',
  SET_AFFILIATE = '@@user/SET_AFFILIATE',
  GET_AFFILIATE = '@@user/GET_AFFILIATE',
  SAVE_AFFILIATE = '@@user/SAVE_AFFILIATE',
  SET_GENERATE_AFFILIATE_LINK = '@@user/SET_GENERATE_AFFILIATE_LINK',
  GET_GENERATE_AFFILIATE_LINK = '@@user/GET_GENERATE_AFFILIATE_LINK',
  RETRIEVE_USER_CHATS = '@@user/RETRIEVE_USER_CHAT',
  SAVE_RETRIEVE_USER_CHATS = '@@user/SAVE_RETRIEVE_USER_CHATS',
  RETRIEVE_SPECIFIC_MESSAGES = '@@user/RETRIEVE_SPECIFIC_MESSAGES',
  SAVE_SPECIFIC_MESSAGES = '@@user/SAVE_SPECIFIC_MESSAGES',
  CLEAR_SPECIFIC_CHAT_DATA = '@@user/CLEAR_SPECIFIC_CHAT_DATA',
  SET_CHATROOM_MESSAGE = '@@user/SET_CHATROOM_MESSAGE',
  GET_CHATROOM_MESSAGE = '@@user/GET_CHATROOM_MESSAGE',
  GET_SUBSCRIPTION_TYPES = '@@user/GET_SUBSCRIPTION_TYPES',
  SET_SUBSCRIPTION_TYPES = '@@user/SET_SUBSCRIPTION_TYPES',
  USER_CREATE_SUBSCRIPTION_TYPES = '@@user/USER_CREATE_SUBSCRIPTION_TYPES',

}

export interface UserInfo {
  id?: number
  first_name?: string
  last_name?: string
  date_of_birth?: Date
  email?: string
  receive_msg?: boolean
  image_url?: string
  home_address?: string
  login_type?: string
  username?: string
  affiliate_code?: string
}

export interface UserDiscount {
  id: number
  user_id: number
  discount_data: string
}

export interface DiscountData {
  default: DefaultDiscount[]
  additional: DefaultDiscount[]
}

export interface DefaultDiscount {
  id: string
  user_id: number | undefined
  discount_name: string
  discount_value: number
  is_enabled: boolean
  is_removable: boolean
}

export enum UserType {
  BUSINESS_OWNER_WITHOUT_SUBSCRIPTION = 1,
  CUSTOMER_WITHOUT_SUBSCRIPTION = 2,
  BUSINESS_OWNER_WITH_SUBSCRIPTION = 3,
  CUSTOMER_WITH_SUBSCRIPTION = 4,
  ADMIN = 5,
}

export interface UserData {
  id?: number
  user_type: UserType
  mobile_number?: string
  email?: string
  country_code: string
  user_info?: UserInfo
  user_address_id?: number
  is_active?: boolean
  user_created_with_id?: number
  is_subscribed?: number
  is_app_subscription?: boolean
  stripe_enabled?: boolean
  language?: string
  created_at?: Date
  updated_at?: Date
  is_valid?: boolean
  is_verified?: boolean
  package_subscribed?: string
  user_discounts?: UserDiscount
  corporate_id?: any
  login_type?: string
  hasMultipleRegistration?: boolean
  username?: string
}

export interface BusinessType {
  id: number
  name: string
}

export interface BusinessInfo {
  business_name: string
  business_Industry: string
  location: string
}

export interface OnboardingStatus { 
  is_need_requirement: boolean
  onboard_complete: boolean
}

export interface UserState {
  readonly userData: UserData
  readonly isValidUser: boolean
  readonly stripeDetails?: StripeDetails
  readonly flagDetails: UserFlagModel[]
  readonly estimateToPay?: PriceEstimateModel
  readonly isConatctInfoUpdatedSucess: boolean
  readonly loading: {
    userData?: boolean
    userInfo?: boolean
    userBusiness?: boolean
    useBathroom?: boolean
    infoData?: boolean // this will be used for everything about loading
  }
  readonly loginCheckUserTypeData: LoginModel
  readonly isZipCodeEligible: boolean
  readonly businessTypes: BusinessType[]
  readonly businessInfo?: BusinessInfo
  readonly specificUser?: any
  readonly productList?: Products
  readonly userBusinessStripeData?: UserBusinessModel
  readonly userRetrieveSubscriptionData?: any
  readonly bathroomData?: any
  readonly bathroomFetchError?: boolean
  readonly stripeOnboardingStatus?: OnboardingStatus
  readonly limitations?: any
  readonly isApiError?: boolean
  readonly sugesstionUsername?: any
  readonly affiliateData?: any
  readonly affiliateLink?: string
  readonly chatData?: any
  readonly specificChatData? : any
  readonly specificChatRoomData? : any
  readonly subscriptionTypes?: any
  // readonly loginData: LoginModel;
  // readonly isSignupForm: boolean;
}

export interface StripeDetails {
  customer_id: string
  setup_intent_id: string
  client_secret: string
  price_model: PriceModel[]
}

export interface PriceModel {
  price_id: string
  lookup_key: string
}

export interface SubscribeRequestModel {
  user_id: number
  unit_amount: number
  success_url: string
  cancel_url: string
  // customer_id: string;
  // client_secret: string;
  price_id: string
  email?: string
}

export interface StripeConnectRequestModel {
  user_id: number
  refresh_url: string
  return_url: string
}

export interface UserUpdateModel {
  key: keyof UserData
  value: any
}

export interface SubscribeModal {
  user_id: number
}

export interface PackageSubscribedModal {
  user_id: number
  user_type: number
  package_subscribed: string
}

export interface CreateActionModel {
  userData: UserData
  navigate: Function
}

export interface SaveUserInfoReqModel {
  user_info: UserInfo
  user_id?: number
  user_type: UserType
  email?: string
  mobile_number?: string
  language?: string
  country_code?: string
  login_type?: string
  username?: string
}

export interface SaveUserInfoActionModel {
  reqModel: SaveUserInfoReqModel
  navigate: Function
}

export interface SaveBusinessInfoReqModel {
  businessName: string
  businessIndustry: string
  location: string
  user_id?: number
}

export interface SaveBusinessReqModel {
  business_name: string
  business_address: string
  user_id?: number
}

export interface SaveBusinessInfoActionModel {
  reqModel: SaveBusinessInfoReqModel
  navigate: Function
}

export interface SaveBusinessActionModel {
  reqModel: SaveBusinessReqModel
  navigate: Function
}

export interface LoginModel {
  email?: string
  mobile_number?: string

  package_subscribed?: string
}

export interface LoginReqModel {
  reqData: LoginModel
  navigate: Function
}

export interface SwitchLoginModel {
  email?: string
  mobile_number?: string
  user_type?: number
}
export interface SwitchLoginReqModel {
  reqData: SwitchLoginModel
  navigate: Function
}

export interface UpgradeReqModel {
  user_id: number
  user_type: number
  session_id: string
}

export interface UserFlagModel {
  company_name: string
  post_id: number
  owner_id: number
  listing_id: number
}

export interface PriceEstimateModel {
  estimate: DamagedItemModel[]
  total: number
}

export interface DamagedItemModel {
  id: number
  name: string | null
  amount: number | null

  image_list?: string
}

export interface VIPRequestModel {
  data: FormData
  navigate: Function
}

export interface ContactUsModel {
  firstName: string
  lastName: string
  email: string
  message: string
}

export interface UserBusinessModel {
  business_name: string
  created_at: Date
  updated_at: Date
  stripe_account_id: string
  address: string
  user_id: number
  delete_flag: boolean
}
export interface Products {
  monthly: Product[];
  yearly: Product[];
}

export interface Product {
  id: string;
  object: string;
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: string;
  custom_unit_amount: null | number;
  livemode: boolean;
  lookup_key: string;
  metadata: Metadata;
  nickname: string;
  product: string;
  recurring: Recurring;
  tax_behavior: string;
  tiers_mode: null | string;
  transform_quantity: null | string;
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
}

export interface Metadata {}

export interface Recurring {
  aggregate_usage: null | string;
  interval: string;
  interval_count: number;
  meter: null | string;
  trial_period_days: null | number;
  usage_type: string;
}

export interface Subscription {
  user_id: number;
  unit_amount: string;
  price_id: string;
  email: string | null;
  name: string | null;
  package_quantity: number;
  interval: string | null;
  token: string | null;
  is_app_subscription?: boolean
}

export interface SpecificMessage {
  senderId: number | undefined,
  recipientId: number | undefined,
  limit?: number,
  page?: number
}



