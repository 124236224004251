import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  List,
  DialogContent,
  ListItem,
  ListItemText,
  Checkbox,
  DialogActions,
} from "@mui/material";
import { getAffiliate } from '../../../../store/app/user/actions'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../store'

interface PayoutStatus {
  registration: 'pending' | 'eligible' | 'paid'
  subscription: 'pending' | 'eligible' | 'paid'
}

interface Affiliate {
  id: string
  username: string
  user_type: number
  package_subscribed: string | null
  account_created: string
  subscription_created: string | null
  registration_payout_date: string | null
  subscription_payout_date: string | null
  payout_status: PayoutStatus
  stripe_account_connected: string
  tsekmo_packages_subscribe: string
}

const Affiliates: React.FC = () => {
  const [selectedPayouts, setSelectedPayouts] = useState<Record<string, string[]>>({})
  const [payoutModalOpen, setPayoutModalOpen] = useState(false)
  const [currentAffiliate, setCurrentAffiliate] = useState<Affiliate | null>(null)
  const [affiliates, setAffiliates] = useState<Affiliate[]>([])

  const dispatch = useDispatch()
  const { affiliateData, userData } = useSelector(
    (state: ApplicationState) => state.user
  ) as {
    affiliateData: Affiliate[];
    userData: any;
  }

  useEffect(() => {
    if (userData?.user_info?.affiliate_code) {
      dispatch(getAffiliate(userData.user_info.affiliate_code))
    }
  }, [userData, dispatch])

  useEffect(() => {
    if (affiliateData && Array.isArray(affiliateData)) {
      setAffiliates(affiliateData)
    }
  }, [affiliateData])

  const handlePayoutSelection = (affiliateId: string, payoutType: string) => {
    setSelectedPayouts(prev => ({
      ...prev,
      [affiliateId]: prev[affiliateId]?.includes(payoutType)
        ? prev[affiliateId].filter(t => t !== payoutType)
        : [...(prev[affiliateId] || []), payoutType]
    }))
  }

  const handlePayoutRequest = (affiliate: Affiliate) => {
    setCurrentAffiliate(affiliate)
    setPayoutModalOpen(true)
  }

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Affiliates Dashboard
      </Typography>
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Registration Payout</TableCell>
                <TableCell>Subscription Payout</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {affiliates && affiliates.map((affiliate) => {
                const registrationDate = affiliate.registration_payout_date 
                  ? new Date(affiliate.registration_payout_date)
                  : null
                
                const subscriptionDate = affiliate.subscription_payout_date 
                  ? new Date(affiliate.subscription_payout_date)
                  : null

                return (
                  <TableRow key={affiliate.id}>
                    <TableCell>{affiliate.username}</TableCell>
                    <TableCell>
                      {registrationDate ? 
                        `${registrationDate.toLocaleDateString()} - ${affiliate.payout_status.registration}` : 
                        'N/A'}
                    </TableCell>
                    <TableCell>
                      {subscriptionDate ? 
                        `${subscriptionDate.toLocaleDateString()} - ${affiliate.payout_status.subscription}` : 
                        'N/A'}
                    </TableCell>
                    <TableCell>
                      {Object.values(affiliate.payout_status).includes('eligible') 
                        ? 'Eligible for Payout' 
                        : 'Pending'}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        disabled={!Object.values(affiliate.payout_status).includes('eligible')}
                        onClick={() => handlePayoutRequest(affiliate)}
                        sx={{
                            backgroundColor: "primary.main",
                            color: "black",
                            "&:hover": {
                              backgroundColor: "primary.dark",
                              color: "white",
                            },
                        }}
                      >
                        Request Payout
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Dialog open={payoutModalOpen} onClose={() => setPayoutModalOpen(false)}>
        <DialogTitle>Select Payouts to Claim</DialogTitle>
        <DialogContent>
          {currentAffiliate && (
            <List>
              <ListItem>
                <ListItemText
                  primary="Registration Payout"
                  secondary={currentAffiliate.registration_payout_date 
                    ? new Date(currentAffiliate.registration_payout_date).toLocaleDateString()
                    : 'N/A'}
                />
                <Checkbox
                  disabled={currentAffiliate.payout_status.registration !== 'eligible'}
                  checked={selectedPayouts[currentAffiliate.id]?.includes('registration') || false}
                  onChange={() => handlePayoutSelection(currentAffiliate.id, 'registration')}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Subscription Payout"
                  secondary={currentAffiliate.subscription_payout_date 
                    ? new Date(currentAffiliate.subscription_payout_date).toLocaleDateString()
                    : 'N/A'}
                />
                <Checkbox
                  disabled={currentAffiliate.payout_status.subscription !== 'eligible'}
                  checked={selectedPayouts[currentAffiliate.id]?.includes('subscription') || false}
                  onChange={() => handlePayoutSelection(currentAffiliate.id, 'subscription')}
                />
              </ListItem>
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPayoutModalOpen(false)}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={() => {
              console.log('Processing payouts:', selectedPayouts[currentAffiliate?.id || ''])
              setPayoutModalOpen(false)
            }}
            sx={{
                backgroundColor: "primary.main",
                color: "black",
                "&:hover": {
                  backgroundColor: "primary.dark",
                  color: "white",
                },
            }}
          >
            Confirm Payout
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default Affiliates