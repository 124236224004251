import React from 'react';
import {
  Modal,
  Button,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  CardActions,
  Divider,
  useTheme,
  Box,
  Collapse,
  IconButton,
} from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { createSubscriptionTypes } from '../../../store/app/user/actions';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '');

interface StripeTier {
  flat_amount: number | null;
  flat_amount_decimal: string | null;
  unit_amount: number | null;
  unit_amount_decimal: string | null;
  up_to: number | null;
}

interface StripeProduct {
  id: string;
  active: boolean;
  billing_scheme: 'per_unit' | 'tiered';
  currency: string;
  nickname: string | null;
  recurring: {
    interval: 'month' | 'year';
    interval_count: number;
  };
  tiers?: StripeTier[];
  unit_amount?: number;
}

interface SubscriptionProducts {
  [key: string]: {
    monthly: StripeProduct[];
    yearly: StripeProduct[];
  };
}

interface RegistrationData {
  email: string;
  name: {
    firstName: string | '';
    lastName: string | '';
  }
}

interface SubscriptionModalProps {
  open: boolean;
  onClose: () => void;
  products: SubscriptionProducts;
  registrationData: RegistrationData;
  onSubscriptionSuccess: () => void;
}

const SubscriptionModal: React.FC<SubscriptionModalProps> = ({
  open,
  onClose,
  products = {},
  registrationData,
  onSubscriptionSuccess,
}) => {
  const dispatch = useDispatch()

  const [expandedTiers, setExpandedTiers] = React.useState<{ [key: string]: boolean }>({});

  const handleSubscribe = async (datum : any) => {
    // const stripe = await stripePromise;
    // if (!stripe) {
    //   console.error('Stripe.js has not loaded.');
    //   return;
    // }

    // const { error } = await stripe.redirectToCheckout({
    //   lineItems: [{ price: priceId, quantity: 1 }],
    //   mode: 'subscription',
    //   successUrl: `${window.location.origin}/success`,
    //   cancelUrl: `${window.location.origin}/cancel`,
    // });

    // if (error) {
    //   console.error('Error redirecting to Stripe checkout:', error);
    // }
    const name = registrationData.name?.firstName + registrationData.name?.lastName; 

    const data = {
      unit_amount: datum.unit_amount,
      price_id: datum.id,
      email: registrationData.email,
      name: name,
      package_quantity: 1,
      interval: datum.recurring.interval,
      is_app_subscription: true,
  };
    dispatch(createSubscriptionTypes(data))
    console.log(`Check data : ${JSON.stringify(data, null, 2)}`);
  };

  const toggleTiers = (priceId: string) => {
    setExpandedTiers(prev => ({ ...prev, [priceId]: !prev[priceId] }));
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 2,
          width: '95%',
          maxWidth: '800px',
          maxHeight: '90vh',
          overflowY: 'auto',
          borderRadius: '12px',
          bgcolor: 'primary.light',
          background: 'linear-gradient(145deg, #e3f2fd 0%, #bbdefb 100%)',
        }}
      >
        <Typography variant="h5" sx={{ mb: 2, color: 'primary.dark', fontWeight: 'bold', textAlign: 'center' }}>
          Choose Your Plan
        </Typography>

        <Grid container spacing={2}>
          {Object.entries(products).map(([planType, varieties]) => (
            <Grid item xs={12} key={planType}>
              <Card sx={{ borderRadius: '8px', boxShadow: '0 4px 12px rgba(0,0,0,0.1)', bgcolor: 'background.paper' }}>
                <CardContent>
                  <Typography variant="h6" sx={{ mb: 2, color: 'primary.main', fontWeight: '600' }}>
                    {planType} Plans
                  </Typography>

                  <Grid container spacing={2}>
                    {['monthly', 'yearly'].map(interval =>
                      varieties[interval as keyof typeof varieties]?.map(product => (
                        <Grid item xs={12} md={6} key={product.id}>
                          <Card variant="outlined" sx={{ height: '100%' }}>
                            <CardContent>
                              <Typography variant="subtitle1" sx={{ fontWeight: '600', color: 'text.primary' }}>
                                {interval.charAt(0).toUpperCase() + interval.slice(1)}
                              </Typography>

                              {product.billing_scheme === 'per_unit' ? (
                                <Typography variant="h4" sx={{ my: 1, color: 'secondary.main', fontWeight: 'bold' }}>
                                  ${product.unit_amount ? product.unit_amount / 100 : 'N/A'}
                                  <Typography component="span" variant="body1" sx={{ color: 'text.secondary', ml: 0.5 }}>
                                    /{interval === 'monthly' ? 'mo' : 'yr'}
                                  </Typography>
                                </Typography>
                              ) : (
                                <>
                                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Starting from
                                  </Typography>
                                  <Typography variant="h5" sx={{ color: 'secondary.main', fontWeight: 'bold' }}>
                                    ${product.tiers?.[0]?.unit_amount ? product.tiers[0].unit_amount / 100 : 'N/A'}
                                    <Typography component="span" variant="body2" sx={{ color: 'text.secondary', ml: 0.5 }}>
                                      /{interval === 'monthly' ? 'mo' : 'yr'}
                                    </Typography>
                                  </Typography>
                                </>
                              )}

                                {product.billing_scheme === 'tiered' && product.tiers && product?.tiers?.length > 0 && (
                                <Box sx={{ mt: 1 }}>
                                    <Button
                                    size="small"
                                    endIcon={<ExpandMoreIcon sx={{ transform: expandedTiers[product.id] ? 'rotate(180deg)' : 'rotate(0deg)' }} />}
                                    onClick={() => toggleTiers(product.id)}
                                    sx={{ color: 'primary.main' }}
                                    >
                                    {expandedTiers[product.id] ? 'Hide' : 'View'} Tiers
                                    </Button>

                                    <Collapse in={expandedTiers[product.id]}>
                                    <Box sx={{ mt: 1, p: 1, bgcolor: 'action.hover', borderRadius: '4px' }}>
                                        {product.tiers?.map((tier, index) => (
                                        <Typography key={index} variant="body2" sx={{ color: 'text.secondary', mb: 0.5 }}>
                                            {tier.up_to
                                            ? `Up to ${tier.up_to} units: $${tier.unit_amount! / 100}`
                                            : `Over ${product.tiers?.[index - 1]?.up_to || 0} units: $${tier.unit_amount! / 100}`}
                                        </Typography>
                                        ))}
                                    </Box>
                                    </Collapse>
                                </Box>
                                )}
                                <CardActions>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        sx={{
                                            backgroundColor: "blue", // Default background color
                                            color: "black", // Default text color
                                            "&:hover": { 
                                              backgroundColor: "#1565c0", // Slightly darker blue on hover
                                              color: "white", // White text on hover for contrast
                                            },
                                          }}
                                        onClick={() => handleSubscribe(product)}
                                    >
                                        Subscribe
                                    </Button>
                                </CardActions>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Modal>
  );
};

export default SubscriptionModal;